.editProfileInput {
  background-color: transparent !important;
  height: 50px;
  border: none !important;
  border-radius: 10px !important;
  color: #848484 !important;
  margin-left: 50px !important;
  width: 90% !important;
}

.editProfileInput::placeholder {
  font-size: 12px;
  color: #848484 !important;
}

.editProfileInput:focus {
  box-shadow: none !important;
  width: 90% !important;
}

.cascaderStyle {
  width: 100% !important;
}
