.navbarBgColor {
  background-color: #000000 !important;
}

.dashboardNavBgColor {
  background-color: #1f1111 !important;
  color: white !important;
}

@media only screen and (max-width: 992px) {
  .first-nav {
    margin-top: 1rem;
  }
}

/* 
.dashboardNavBgColor {
    background-color: yellow !important;
} */

.logoView {
  background-color: #cccccc;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  align-items: center;
}

.menuBarWebView {
  display: block;
}

.menuBarMobView {
  display: none;
}

.walletBtn {
  border: 1px solid #c13636;
  width: 150px;
  height: 35px;
  border-radius: 20px;
}

.connentbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .walletBtn {
    border: 1px solid #c13636;
    width: 115px;
    height: 35px;
    border-radius: 20px;
    font-size: 10px;
  }

  .loginbtn {
    margin-left: -2.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .connentbtn {
    display: none;
  }
}

@media only screen and (min-width: 575px) {
  .connectIcon {
    display: none;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.55) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

@media only screen and (max-width: 600px) {
  .menuBarWebView {
    display: none;
  }

  .menuBarMobView {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .menuBarWebView {
    display: none;
  }

  .menuBarMobView {
    display: block;
  }
}

.chainDiv {
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2rem;
}

@media only screen and (max-width: 992px) {
  .chainDiv {
    margin-bottom: 1rem;
  }
}

.leftChainDiv {
  background-color: #141414;
  border-radius: 17px 0px 0px 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #adadad;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}

.rightChainDiv {
  background-color: #222222;
  border-radius: 0px 17px 17px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #adadad;
  padding: 0.5rem 1.5rem;
}

.ethIcon {
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 990px) {
  .bottom-nav {
    display: none !important;
  }

  .chainDiv {
    display: flex;
    /* gap: 2rem; */
    justify-content: center;
    margin-top: 1rem;
  }
}

@media (min-width: 999px) {
  .topmenu {
    display: none !important;
  }
}

@media (max-width: 999px) {
  .nav-logo {
    display: none !important;
  }
}

.logoutModal .ant-modal-content {
  background-color: #fff !important;
  left: 150%;
}
.logoutModal .ant-modal-body {
  padding: 5px 20px 20px 20px;
}
.logoutModal .ant-modal-close-x {
  width: 10px;
  height: 10px;
  transform: translate(-15px, -15px);
}

@media (max-width: 1399.98px) {
  .logoutModal .ant-modal-content {
    left: 120%;
  }
}

@media (max-width: 1199.98px) {
  .logoutModal .ant-modal-content {
    left: 90%;
  }
}

@media (max-width: 991.98px) {
  .logoutModal .ant-modal-content {
    left: 0%;
  }
}

.red {
  filter: invert(28%) sepia(95%) saturate(5744%) hue-rotate(343deg)
    brightness(101%) contrast(98%);
}

.hidden {
  display: none;
}
