.dragVideoView {
  flex-direction: column;
  align-items: center;
  border: 2px dashed #b23232;
}

.uploadIconView {
  background-color: #b23232;
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.greyBgInput {
  background-color: #343434 !important;
  border: none !important;
  color: #ffffff !important;
}

.greyBgInput::placeholder {
  color: #535353 !important;
}
