.mainWrapper {
  background-color: #fff !important;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e8e8e8;
}

.subWrapper {
  padding: 0rem 1.5rem;
}

.closeButton {
  display: block;
  margin: 2rem auto 0 auto;
  border: none;
  background: linear-gradient(180deg, #df4747 0%, #8a1818 100%);
  border-radius: 6.5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: #ffffff;
  width: 127px;
  height: 53px;
}

.closeIcon {
  margin-bottom: 3px;
  font-size: 20px;
}

.mainWrapper .ant-steps-item-icon .ant-steps-icon {
  top: -2.5px !important;
}

.mainWrapper .ant-steps-item-icon {
  border-radius: 10px !important;
  border: none !important;
  background: gray !important;
}

.mainWrapper
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: linear-gradient(180deg, #df4747 0%, #8a1818 100%) !important;
}

.mainWrapper .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}

.mainWrapper .ant-steps-item-finish .ant-steps-item-icon {
  background: linear-gradient(180deg, #df4747 0%, #8a1818 100%) !important;
}
.mainWrapper .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}

.mainWrapper .ant-steps-item-title {
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #282828;
  font-size: 24px;
  line-height: 38px;
  font-family: "Nunito Sans", sans-serif;
}

.mainWrapper .ant-steps-item-title::after {
  background-color: #c2c2c2 !important;
  height: 2px;
}
