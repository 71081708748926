.footer-logo {
  position: fixed;
  bottom: 5px;
  right: 10px;
  z-index: 100;
}

@media (min-width: 992px) {
  .footer-logo {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .footer-logo {
    right: 2%;
  }
}
