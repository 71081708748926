/* .main-wrapper {
  overflow: hidden;
  min-height: 235px;
  background: #ffffff;
  border: 0.5px solid #00cea2;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0.5rem 1rem;
} */

.main-wrapper h2 {
  color: aliceblue;
  font-size: 12px;
}

.info {
  margin-top: 1rem;
}
